import firebase from 'firebase/compat/app'
import {dbRef, functionsRef} from '../firebase/firebase'
import * as Sentry from '@sentry/browser'

// Update the current user record.
export const addAccountInfo = (account, uid) => {
  let changedTime = {
    changed: (new Date()).getTime(),
    modifiedBy: 'widget',
    revisionId: firebase.firestore.FieldValue.increment(1)
  }
  return dbRef.collection('veterans')
    .doc(uid)
    .set(
      {
        ...account,
        ...changedTime,
      },
      {merge: true},
    )
}

// Update/Write session into the DB.
export const writeSession = (updates, sessionId) =>
  dbRef.collection('sessions')
    .doc(sessionId)
    .set(updates, {merge: true})

// Get all the fields in the user document from DB.
export const doRetrieveCustomToken = (sessionId) => {
  return dbRef.collection('sessions')
    .doc(sessionId)
    .collection('login_session')
    .doc(sessionId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data()
      }
      else {
        throw new Error('Collection does not exist')
      }
    })
}

// Get primary account user data.
export const getHHMemberPrimary = () => {
  const getHHMemberPrimary = functionsRef.httpsCallable('groupAuthHelpers-getHHMemeberPrimary')
  return getHHMemberPrimary()
}

// Retrieve JWT Kustomer token.
export const getJWTKustomerToken = () => {
  const JWTKustomerToken = functionsRef.httpsCallable('groupAuthKustomer-jwtAuth')
  return JWTKustomerToken()
}

// Load Scripts.
export const loadScript = (url, key) => new Promise((resolve, reject) => {
  let ready = false

  if (!document) {
    const error = new Error('Document was not defined')
    Sentry.captureException(error);
    console.log(error)
    reject(error)
  }

  const tag = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')

  script.type = 'text/javascript'
  if (key) {
    var queryDict = {}
    window.location.search.substr(1).split('&').forEach(
      function(item) {
        queryDict[item.split('=')[0]] = item.split('=')[1]
      })
    if (queryDict.providerToken && queryDict.providerToken.match(/^[0-9a-zA-Z]{1,34}$/)) {
      script.src = url + '?' + key + '=' + queryDict.providerToken
    }
    else if (queryDict.mode && queryDict.mode === 'resetPassword') {
      resolve(true)
    }
    else {
      const error = new Error('Query for script not existing.')
      Sentry.captureException(error);
      console.log(error)
      reject(error)
    }
  }
  else {
    script.src = url
  }

  script.async = false
  script.onreadystatechange = () => {
    if (!ready) {
      ready = true
      resolve(script)
    }
  }
  script.onload = script.onreadystatechange

  script.onerror = (msg) => {
    console.log(msg)
    reject(new Error('Error loading script.'))
  }

  script.onabort = (msg) => {
    console.log(msg)
    reject(new Error('Script loading aborted.'))
  }

  if (tag.parentNode != null) {
    tag.parentNode.insertBefore(script, tag)
  }
})

export const getAppUrl = (name) => {
  let url = `https://${name}`;
  const envs = ['dev', 'stage']
  if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
    url += process.env.REACT_APP_ENV === 'stage' ? '-stg' : '-dev'
  }
  return url + '.wesalute.com';
}