import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import * as routes from '../../../constants/routes'

const BackButton = (props) => {

  const history = useHistory()
  const location = useLocation()

  const goBack = () => {
    switch (location.pathname) {
      case routes.STATIC_SEND_PARTNER_DATA:
        document.body.classList.remove('page-slate')
        history.push(routes.MDM_DEALS)
        break
      case routes.SELECT_PLAN:
        history.push(routes.MDM_DEALS)
        break
      default:
        break
    }
  }

  return (
    <button className="btn-back" onClick={() => goBack()}/>
  )
}

export default BackButton
