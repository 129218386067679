import React from 'react';

const Header = ({brandData}) => {
	const logo = brandData?.lightLogo ? brandData?.lightLogo : brandData?.logo
	const alt = brandData?.label ?? 'partners logo'
	return (
		<div className='logos'>
			<div className='logo'>
				<img src='/assets/images/header-logo.svg' alt='logo' />
			</div>
			{logo ? (
				<div className='brand-logo'>
					<img src={logo} alt={alt} className='avatar'/>
				</div>
			) : null}
		</div>
	);
};

export default Header;