const INITIAL_STATE = {
  title: '',
  btn: true,
  back: true,
  desc: '',
  active: false,
  externalBtn: false,
  maintenance: false,
}

function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ERROR_PAGE' : {
      if (state.maintenance && typeof (action.payload.maintenance) === 'undefined') {
        return state
      }
      else {
        return {...state, ...action.payload}
      }
    }
    default :
      return state
  }
}

export default errorReducer
