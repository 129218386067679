/**
 * Log helper to log messages on needed env only.
 *
 * @param data
 * @param type
 * @param env
 */
export const logHelper = (data, env = null, type = 'log') => {
	if ((!env || env === process.env.REACT_APP_ENV) && console?.[type]) {
		console[type](data)
	}
}