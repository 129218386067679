import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as routes from '../../constants/routes'
import {auth, analytics, helpers, db} from '../../actions'
import {firebase} from '../../firebase'
import Preloader from '../Preloader'
import {useHistory} from 'react-router-dom'
import {isFullyVerified} from "../../actions/identification";
import {setErrorPage} from "../../actions/db";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import {logHelper} from "../../actions/log";
import {getAppUrl} from "../../actions/helpers";


const Sign = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const accountData = useSelector(state => state.accountState.account)
  const authUser = useSelector(state => state.sessionState.authUser)
  const sessionDBId = useSelector(state => state.sessionDBState.id)
  const staticData = useSelector(state => state.textsState.data).find(x => x.id === 'HouseHold Members Messages').data()
  const {sessionId} = useSelector(state => state.sessionDBState.data.urlQuery)
  const [tokenRequestSent, setTokenRequestSent] = useState(false)

  const {
    providerToken,
    signOutUser,
    publicComputer,
  } = props

  useEffect(async () => {
    if (authUser && !signOutUser) {
      if (accountData) {
        // If a user is logged in already, verified, redirect to marketplace.
        if (
          accountData?.roles &&
          (
            accountData.roles.includes('vr_household') ||
            accountData.roles.includes('va_household')
          )
        ) {
          const hhMemberPrimaryVerified = await checkHouseholdMembers(accountData);
          if (!hhMemberPrimaryVerified) {
            dispatch(
              setErrorPage(staticData.ParentVerifyAlertTitle, false, false, staticData.ParentVerifyAlertDesc),
            )
          }
          else {
            // Allow HH members with verified Primary users Deals access.
            history.push(routes.MDM_DEALS)
          }
        }
        else if (isFullyVerified(accountData)) {
          // Allow all other verified non HH members Deals access.
          history.push(routes.MDM_DEALS)
        }
        else {
          redirectToAuthApp()
        }
      }
    }
    else if (signOutUser) {
      // Redirect to AuthApp signout.
      redirectToAuthApp()
    }
    else if (sessionId) {
      helpers.doRetrieveCustomToken(sessionId)
        .then((loginSessionData) => {
          if (loginSessionData?.loginToken) {
            logHelper("MilVetID Logged in with db token", "dev");
            loginWithCustomToken(loginSessionData.loginToken)
          }
          else {
            console.log("Missing token")
            redirectToAuthApp()
          }
        })
        .catch((error) => {
          console.log(error)
          redirectToAuthApp()
        })
    }
    // If session cookie available.
    else if (navigator.cookieEnabled && !tokenRequestSent) {
      const customToken = await getCustomToken().catch(error => {
        // Login via db session (works in incognito browser mode only).
        console.error("Error getting token", error)
        redirectToAuthApp()
      })
      if (customToken?.data?.token) {
        logHelper("MilVetID Logged in with token", "dev");
        loginWithCustomToken(customToken?.data?.token)
      }
      else {
        console.error("Wrong token structure")
        redirectToAuthApp()
      }
    }
    else {
      redirectToAuthApp()
    }

    // eslint-disable-next-line
  }, [authUser, sessionId, accountData])

  const redirectToAuthApp = () => {
    if (!signOutUser) {
      analytics.page('Sign Up')
    }

    const returnUrl = getAppUrl('milvetid')
    let redirectUrl = getAppUrl('auth') +
      '/?providerToken=' + providerToken +
      '&sessionId=' + sessionDBId +
      '&return_to=' + encodeURIComponent(returnUrl) +
      '&signOutUser=' + signOutUser
    if (publicComputer) redirectUrl += '&publicComputer=' + publicComputer

    window.location.href = redirectUrl
  }

  const getCustomToken = async () => {
    const options = {
      method: 'post',
      url: "/session-cookie",
      data: {
        key: process.env.REACT_APP_API_KEY,
      },
      withCredentials: true,
    }
    setTokenRequestSent(true)
    return axios(options)
  }

  const loginWithCustomToken = (token) => {
    firebase.authRef.setPersistence(firebase.persistenceNone)
      .then(() => {
        return auth.doSignInWithCustomToken(token)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * HouseHoldMembers Primary user verification helper.
   *
   * @param accountData
   * @returns {Promise<firebase.functions.HttpsCallableResult | boolean>}
   */
  const checkHouseholdMembers = async (accountData) => {
    return helpers.getHHMemberPrimary()
      .then((result) => {
        const data = result?.data;
        if (!data) {
          return false;
        }
        if (isFullyVerified(data)) {
          dispatch(
            db.updateAccountData({
              identification: result.data.identification,
              roles: result.data.roles.concat(accountData.roles),
              hhmParentUpdated: true,
            }),
          )
          return true;
        }
      })
      .catch(error => {
        console.error(error);
        Sentry.captureException(error)
        return false;
      })
  }

  return (
    <div className="page-signup">
      <Preloader/>
    </div>
  )
}

export default Sign
