import React from 'react'
import Deal from './Deal'
import * as routes from '../../../constants/routes'
import {db} from '../../../actions'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getAppUrl} from "../../../actions/helpers";

const DealsList = (props) => {
  const {
    staticData,
    brandData,
    dealTypes,
    providerToken,
    setRedirected
  } = props

  const {
    updateSession,
  } = db

  const history = useHistory()
  const dispatch = useDispatch()

  const configData = useSelector(state => state.configState.config)
  const dealsData = useSelector(state => state.dealsState.deals)
  const sessionDB = useSelector(state => state.sessionDBState)

  const handleClick = (event) => {
    dispatch(
      updateSession({
        selected_deal_id: event.target.dataset.id,
      }, sessionDB.id),
    )

    event.preventDefault()

    history.push(routes.STATIC_SEND_PARTNER_DATA)
  }

  const handleClickUpdate = (event) => {
    dispatch(
      updateSession({
        selected_deal_id: event.target.dataset.id,
      }, sessionDB.id),
    )

    event.preventDefault()
    setRedirected(true)
    // Redirect user to CheckoutApp.
    window.location.href =
      getAppUrl('checkout') + '/?return_to=' +
      encodeURIComponent(
        getAppUrl('milvetid') + '?providerToken=' + providerToken +
        '&sessionId=' + sessionDB.id + '&version=latest&xcomponent=1'
      ) + '&iframe=1'
  }

  return (
    <div className="deals">
      {Object.keys(dealsData).map(key => {
          const deal = dealsData[key].data()
          let showDeal = false
          let dealAccess = []
          let redemptionType = ''

          if (
            typeof (deal.redemptions) === 'undefined' ||
            !deal.redemptions ||
            !deal.status
          ) {
            return false
          }

          dealTypes.forEach(dealsTypeCheck)

          function dealsTypeCheck(dealType) {
            if (deal.redemptions) {
              deal.redemptions.forEach(checkDealType)
            }

            function checkDealType(redemption) {
              if (
                redemption &&
                redemption.redemptionType &&
                dealType === redemption.redemptionType
              ) {
                showDeal = true
                redemptionType = dealType
                dealAccess = dealAccess.concat(redemption.redemptionAccess)
              }
            }
          }

          let term = deal['termsOfConditions'] ? deal['termsOfConditions'] : false

          if (showDeal) {
            return (
              <Deal
                key={key}
                deal={deal}
                weight={deal['weight'] ? deal['weight'] : 0}
                image={deal['image']}
                title={deal['title']}
                description={deal['details']}
                dealAccess={dealAccess}
                redemptionType={redemptionType}
                terms={term}
                dataid={dealsData[key].id}
                staticData={staticData}
                brandData={brandData}
                handleClick={handleClick}
                handleClickUpdate={handleClickUpdate}
              />
            )
          }
          else {
            return false
          }
        },
      )}
    </div>
  )
}

export default DealsList
