import React, {Component} from 'react'
import ContactUs from '../StaticPages/ContactUs'
import * as Sentry from "@sentry/browser";

const errorTexts = {
  'title': 'Oops! There seems to be a problem.',
  'description': 'There is a problem connecting with our services. Check your internet connection and please try again later.',
  'chat_link': 'https://www.veteransadvantage.com/about/contact',
  'chat_link_label': 'Chat with our VIP Support Team',
  'call_link': 'tel://+18668382774',
  'call_link_label': 'Call now: 1-866-VET-ASSIST',
}

class ErrorBoundary extends Component {

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true})
    Sentry.captureException(error)
    // You can also log the error to an error reporting service
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <main className="app">
          <ContactUs
            staticData={errorTexts}
          />
        </main>
      )

    }
    return this.props.children
  }
}

export default ErrorBoundary
