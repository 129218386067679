export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const FETCH_ACCOUNT_UPDATE = 'FETCH_ACCOUNT_UPDATE'
export const FETCH_DEALS = 'FETCH_DEALS'
export const NO_DEALS_FOUND = 'NO_DEALS_FOUND'
export const FETCH_PLANS = 'FETCH_PLANS'
export const SELECT_PLAN = 'SELECT_PLAN'
export const FETCH_STATIC_TEXT = 'FETCH_STATIC_TEXT'
export const FETCH_BRAND_INFO = 'FETCH_BRAND_INFO'
export const SESSION_DB_ID = 'SESSION_DB_ID'
export const SESSION_DB_UPDATE = 'SESSION_DB_UPDATE'
export const FETCH_CONFIG = 'FETCH_CONFIG'
export const FETCH_CONFIG_CHAT = 'FETCH_CONFIG_CHAT'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_DISCOUNTS = 'FETCH_DISCOUNTS'
export const SET_ERROR_PAGE = 'SET_ERROR_PAGE'
