import {authRef, google_provider, facebook_provider, emailAuth} from '../firebase/firebase'

// Sign Up with email and password.
export const doCreateUserWithEmailAndPassword = (mail, password) =>
  authRef.createUserWithEmailAndPassword(mail, password)

// Sign In with email and password.
export const doSignInWithEmailAndPassword = (mail, password) =>
  authRef.signInWithEmailAndPassword(mail, password)

// Sign In With Google Provider.
export const doSignInWithGoogle = () =>
  authRef.signInWithPopup(google_provider)

// Sign In With Facebook Provider.
export const doSignInWithFacebook = () =>
  authRef.signInWithPopup(facebook_provider)

// Sign In With Token.
export const doSignInWithCustomToken = (token) =>
  authRef.signInWithCustomToken(token)

// Sign out.
export const doSignOut = () =>
  authRef.signOut()

// Password Reset.
export const doPasswordReset = (mail) =>
  authRef.sendPasswordResetEmail(mail)

export const verifyPasswordResetCode = (actionCode) =>
  authRef.verifyPasswordResetCode(actionCode)

export const doDeleteCurrentUser = () =>
  authRef.currentUser.delete()

export const confirmPasswordReset = (actionCode, newPass) =>
  authRef.confirmPasswordReset(actionCode, newPass)

// Update Account with a provider.
export const doAccountCredentalsUpdate = (email, password) =>
  authRef.currentUser.linkAndRetrieveDataWithCredential(emailAuth(email, password))
    .then(function(usercred) {
        var user = usercred.user
        console.log('Account linking success', user)
      },
      function(error) {
        console.log('Account linking error', error)
      })
