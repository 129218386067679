import {dbRef, functionsRef} from '../firebase/firebase'
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_UPDATE,
  FETCH_DEALS,
  NO_DEALS_FOUND,
  FETCH_STATIC_TEXT,
  FETCH_BRAND_INFO,
  SESSION_DB_ID,
  SESSION_DB_UPDATE,
  FETCH_CONFIG,
  FETCH_CONFIG_CHAT,
  SET_ERROR_PAGE,
} from '../constants/actiontypes'

// Generate unique session ID.
export const createSessionId = (existingSessionId) => dispatch => {
  function uniqueID() {
    function chr4() {
      return Math.random().toString(16).slice(-4)
    }

    return chr4() + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() +
      '-' + chr4() + chr4() + chr4()
  }

  const sessionId = existingSessionId ? existingSessionId : uniqueID()

  dispatch({
    type: SESSION_DB_ID,
    payload: sessionId,
  })
}

export const onSetAuthUser = (authUser) => dispatch => {
  dispatch({
    type: 'AUTH_USER_SET',
    authUser,
  })

  // If user logs out, reset accountData.
  if (!authUser) {
    dispatch({
      type: 'FETCH_ACCOUNT',
      payload: null,
    })
  }
}

// Update/Write session into the Store.
export const updateSession = (updates) => dispatch => {
  dispatch({
    type: SESSION_DB_UPDATE,
    payload: updates,
  })
}

// Get all the fields in the user document from DB.
export const fetchAccountInfo = uid => dispatch =>
  dbRef.collection('veterans')
    .doc(uid)
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_ACCOUNT,
          payload: doc.data(),
        })
      }
      else {
        dispatch({
          type: FETCH_ACCOUNT,
          payload: null,
        })
      }
    })

export const updateAccountData = (data) => dispatch =>
  dispatch({
    type: FETCH_ACCOUNT_UPDATE,
    payload: data,
  })

// Get all the deals filtered by brand.
export const fetchDealsInfo = (brand, exclusiveDealsOnly, communityDealsOnly) => dispatch => {
  let dealsDocs = []

  function dispatchDeals() {
    dispatch({
      type: FETCH_DEALS,
      payload: dealsDocs,
    })
  }

  var dealProcessed = 0

  const fillDeals = (dealId, index, array) => {
    dbRef.collection('nodes_offer')
      .doc(dealId)
      .onSnapshot((doc) => {
        dealProcessed++

        if (doc.exists) {
          let deal = doc.data()

          if (exclusiveDealsOnly && deal.dealExclusive === exclusiveDealsOnly) {
            dealsDocs.push(doc)
          }
          else if (communityDealsOnly && deal.communityDeal === communityDealsOnly) {
            dealsDocs.push(doc)
          }
          else if (!exclusiveDealsOnly && !communityDealsOnly) {
            dealsDocs.push(doc)
          }

        }

        if (dealProcessed === array.length) {
          dispatchDeals()
        }
      })
  }

  dbRef.collection('nodes_brand')
    .doc(brand)
    .onSnapshot((doc) => {
      if (doc.exists) {
        doc.data().offers.forEach(fillDeals)
      }
      else {
        dispatch({
          type: NO_DEALS_FOUND,
          payload: false,
        })

        dispatch({
          type: SET_ERROR_PAGE,
          payload: {
            title: 'There are no deals to be shown right now.',
            btn: true,
            back: true,
            desc: '',
            active: false,
          },
        })

      }
    })
}

// Get brand filtered by brand name,
export const fetchBrandInfo = providerToken => dispatch => {
  dbRef.collection('accessDomain')
    .doc(providerToken)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.exists) {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: false,
        })
      }
      else {
        return querySnapshot.data().brandUuid
      }
    })
    .then(brandId => {
      if (brandId) {
        dbRef.collection('nodes_brand')
          .doc(brandId)
          .onSnapshot((querySnapshot) => {
            if (!querySnapshot.exists) {
              dispatch({
                type: FETCH_BRAND_INFO,
                payload: false,
              })
            }
            else {
              dispatch({
                type: FETCH_BRAND_INFO,
                payload: querySnapshot,
              })
            }
          })
      }
      else {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: false,
        })
      }
    })
}

// Get all the static texts for the site.
export const fetchStaticTexts = () => dispatch => {
  dbRef.collection('static_page').get().then((querySnapshot) => {
    if (querySnapshot.empty) {
      dispatch({
        type: FETCH_STATIC_TEXT,
        payload: false,
      })
    }
    else {
      dispatch({
        type: FETCH_STATIC_TEXT,
        payload: querySnapshot.docs,
      })
    }
  })
}

// Get the DB config collection.
export const fetchConfig = e => dispatch => {
  dbRef.collection('config')
    .doc('va_user')
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_CONFIG,
          payload: doc.data(),
        })
      }
      else {
        dispatch({
          type: FETCH_CONFIG,
          payload: null,
        })
      }
    })

  dbRef.collection('config')
    .doc('va_zendesk')
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_CONFIG_CHAT,
          payload: doc.data(),
        })
      }
      else {
        dispatch({
          type: FETCH_CONFIG_CHAT,
          payload: null,
        })
      }
    })

}

// Dispatch the error page.
export const setErrorPage = (title, btn, back, desc, external, maintenance) => dispatch => {
  let btnVisible = true
  let backBtnVisible = true
  let description = ''
  let externalBtnVisible = external

  if (typeof (desc) !== 'undefined') {
    description = desc
  }

  if (typeof (back) !== 'undefined' && !back) {
    backBtnVisible = back
  }

  if (typeof (external) !== 'undefined' && !external) {
    externalBtnVisible = false
  }

  if (typeof (btn) !== 'undefined' && !btn) {
    btnVisible = false
  }

  let payload = {
    title: title,
    btn: btnVisible,
    back: backBtnVisible,
    desc: description,
    active: true,
    externalBtn: externalBtnVisible,
  }

  if (maintenance) {
    payload.maintenance = true
  }

  dispatch({
    type: SET_ERROR_PAGE,
    payload: payload,
  })
}

// Clear the error page.
export const clearErrorPage = (maintenance) => dispatch => {
  let payload = {
    title: '',
    btn: true,
    back: true,
    desc: '',
    active: false,
    externalBtn: false,
  }

  if (maintenance) {
    payload.maintenance = false
  }

  dispatch({
    type: SET_ERROR_PAGE,
    payload: payload,
  })
}
