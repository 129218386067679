const INITIAL_STATE = {
  status: "loading",
  config: {},
  chat: null,
};

const applyConfig = (state, action) => ({
  ...state,
  status: "loaded",
  config:  action.payload,
});

const applyConfigChat = (state, action) => ({
  ...state,
  chat: action.payload,
});

function configReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case 'FETCH_CONFIG' : {
      return applyConfig(state, action);
    }
    case 'FETCH_CONFIG_CHAT' : {
      return applyConfigChat(state, action);
    }
    default : return state;
  }
}

export default configReducer;
