import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {db} from '../../actions'
import * as Sentry from '@sentry/browser'

const Preloader = (props) => {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(false)
  const brandData = useSelector(state => state.brandState.data)
  const brandDataStatus = useSelector(state => state.brandState.status)

  const {
    waitFor,
    errorTitle,
    title,
  } = props

  useEffect(() => {
    dispatch(
      db.clearErrorPage(),
    )

    const preloaderTimeout = setTimeout(() => {
      setRedirect(true)
    }, waitFor)

    return () => {
      clearTimeout(preloaderTimeout)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (redirect === true) {
      dispatch(
        db.setErrorPage(errorTitle),
      )
      if (brandDataStatus !== 'loaded') {
        const error = new Error('API timed out.')
        Sentry.captureException(error)
        console.error(error)
      }
      else {
        const error = new Error(brandData.data().label + ' API timed out.')
        Sentry.captureException(error)
        console.error(error)
      }
    }
  }, [redirect, brandDataStatus, brandData, dispatch, errorTitle])

  return (
    <div className="spinner">
      <div className="animation">
      </div>
      {title !== '' &&
      <div className="title">
        {title}
      </div>
      }
    </div>
  )
}


Preloader.defaultProps = {
  title: '',
  errorTitle: 'Oops! There seems to be a problem, don\'t hesitate to contact us.',
  waitFor: 30000, // Wait 30 sec, then set the Error page.
}

export default Preloader

