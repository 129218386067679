const INITIAL_STATE = {
  status: 'loading',
  discounts: {},
}

const applySetDiscounts = (state, action) => ({
  status: 'loaded',
  discounts: action.payload,
})

function discountsReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_DISCOUNTS' : {
      if (action.payload) {
        return applySetDiscounts(state, action)
      }
      else {
        return state
      }
    }
    default :
      return state
  }
}

export default discountsReducer
