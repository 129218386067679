import React, {useEffect, useState} from 'react'
import {
  useHistory,
} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import InputRange from 'react-input-range'
import {db, analytics, helpers} from '../../../actions'
import Preloader from '../../Preloader/Preloader'
import BackButton from '../../Common/BackButton'
import * as routes from '../../../constants/routes'

const SendToPartnerConsent = (props) => {
  const {
    staticData,
    brandId,
    brandData,
    hideDeals,
    dataPartner,
  } = props

  const {
    setErrorPage,
  } = db

  const dispatch = useDispatch()
  const history = useHistory()

  const authUser = useSelector(state => state.sessionState.authUser)
  const accountData = useSelector(state => state.accountState.account)
  const sessionDB = useSelector(state => state.sessionDBState)

  const [label, setLabel] = useState(staticData.label_verify)
  const [labelPosition, setLablePosition] = useState('right')
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [timer, setTimer] = useState(false)

  useEffect(() => {
    if (!authUser || props.signOutUser) {
      history.push(routes.SIGN)
    }
  }, [authUser, history, props.signOutUser])

  useEffect(() => {
    document.body.classList.add('page-slate')

    if (typeof (sessionDB) === 'undefined' ||
      typeof (sessionDB.data.selected_deal_id) === 'undefined') {
      // Set error that the session deal is not present.
      dispatch(
        setErrorPage('We could not find the session for your request, please contact us.', false),
      )
    }
    else {
      helpers.writeSession({
        selected_deal: sessionDB.data.selected_deal_id,
        last_update: new Date(),
      }, sessionDB.id)
    }

    if (typeof (accountData.consents) !== 'undefined' && Array.isArray(accountData.consents)) {
      if (accountData.consents.find((item) => item.id === brandId)) {
        acceptTerms()
      }
      else {
        setLoading(false)
      }
    }
    else {
      setLoading(false)
    }

    analytics.page('Send To Partner Consent', {uid: authUser.uid})

    return () => document.body.classList.remove('page-blue')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (value > 10 && value <= 94) {
      setLabel(staticData.label_confirm)
      setLablePosition('right')
    }
    else if (value > 94) {
      setLabel(staticData.label_lets_verify)
      setLablePosition('left')

      setTimeout(() => acceptTerms(), 1000)
    }
    // eslint-disable-next-line
  }, [value])

  const sendData = () => {
    analytics.track('Offer Redemption Started', {
      ...sessionDB.data.selected_deal_tracking,
      context: {
        app: {
          name: 'MilVetID',
          namespace: 'components/StaticPages/SendToPartnerConsent',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
    })

    if (typeof (dataPartner) !== 'undefined') {
      dataPartner(sessionDB.id, sessionDB.data.selected_deal_id, accountData.memberId)
    }
  }

  const completeSlider = (numberOfSteps) => {
    if (!timer) {
      var steps = 0
      setTimer(true)
      var slideTimer = setInterval(() => {
        steps += 1
        setValue(steps * (100 / numberOfSteps))
        if (steps === numberOfSteps) {
          clearInterval(slideTimer)
        }
      }, 8)
    }
  }

  const userDeliveredToClient = () => {
    analytics.track('User Delivered To Client', {
      company: brandData.label,
      memberId: accountData.memberId,
      partnerAccessKey: brandId,
      context: {
        app: {
          name: 'MilVetID',
          namespace: 'components/StaticPages/SendToPartnerConsent',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
    })
  };

  const dataConsentGiven = () => {
    // Track Data Consent Given event when consent given to the partner for the first time.
    analytics.track('Data Consent Given', {
      partnerId: brandId,
      partnerName: brandData.label,
      sharedData: ['sessionId', 'dealId'],
      context: {
        app: {
          name: 'MilVetID',
          namespace: 'components/StaticPages/SendToPartnerConsent',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
    })
  }

  const acceptTerms = () => {
    if (typeof (accountData.consents) !== 'undefined' && Array.isArray(accountData.consents)) {
      let consentData = accountData.consents

      if (!accountData.consents.find((item) => item.id === brandId)) {
        // Track Data Consent Given and User Delivered To Client when consent given to the partner for the first time.
        dataConsentGiven()
        userDeliveredToClient()

        consentData.push({
          id: brandId,
          name: brandData.label,
          consent_granted: true,
          last_update: new Date(),
          timestamp: new Date(),
        })

        helpers.addAccountInfo({
          consents: consentData,
        }, authUser.uid).then(() => {
          sendData()

          window.xprops.close()
        })
      }
      else {
        // Track User Delivered To Client event when user already granted an access to partner.
        userDeliveredToClient()

        let consentDataIndex = consentData.findIndex((item) => item.id === brandId)

        consentData[consentDataIndex] = {
          ...consentData[consentDataIndex],
          last_update: new Date(),
        }

        helpers.addAccountInfo({
          consents: consentData,
        }, authUser.uid).then(() => {
          sendData()
          setTimeout(() => window.xprops.close(), 1000)
        })
      }
    }
    else {
      // Track Data Consent Given and User Delivered To Client events when consent given for the first time.
      dataConsentGiven()
      userDeliveredToClient()

      helpers.addAccountInfo({
        consents: [
          {
            id: brandId,
            name: brandData.label,
            consent_granted: true,
            last_update: new Date(),
            timestamp: new Date(),
          },
        ],
      }, authUser.uid).then(() => {
        sendData()

        window.xprops.close()
      })
    }
  }

  const contact = () => {
    dispatch(
      setErrorPage('Have questions about data consent?', false, true, 'Contact our VIP Member Support Team for answers.'),
    )
  }

  const description = staticData.description.replace('@brand', brandData.label)

  return (
    <div className="static-page">
      {loading && <Preloader title="Checking your consents. Please wait."/>}
      {!loading && <React.Fragment>
        {!hideDeals &&
        <BackButton/>
        }
        <div className="info">
          <h1>{staticData.title}</h1>
          <div className="info-description">{description}</div>
          <ul className="size-big">
            {Object.keys(staticData.data_send).map(key => {
              // Remove the item if it contains word 'deal' or 'Deal' and hideDeals flag is set.
              if (
                hideDeals &&
                (
                  staticData.data_send[key].includes('Deal') ||
                  staticData.data_send[key].includes('deal')
                )
              ) {
                return ('')
              }

              let dataItem = staticData.data_send[key].replace('@brand', brandData.label)
              return (
                <li key={key}>
                  {dataItem}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="confirm">
          <div className={'confirm-labels label-position-' + labelPosition}>
            {label}
          </div>
          <InputRange
            maxValue={100}
            minValue={0}
            value={value}
            onChange={(option) => {
              if (value < option) {
                completeSlider(8)
              }
            }}
          />
        </div>
        <button className="do-not-consent-link btn btn-clear no-hover" onClick={() => contact()}>{staticData.i_do_not_consent}</button>
      </React.Fragment>}
    </div>
  )
}

export default SendToPartnerConsent
