import React, {useState} from 'react'
import Parser from 'html-react-parser'
import {analytics, db} from '../../../../actions'
import {useDispatch, useSelector} from 'react-redux'

const Deal = (props) => {
  const {
    staticData,
    deal,
    redemptionType,
    terms,
    dataid,
    dealAccess,
    handleClick,
    handleClickUpdate,
    brandData,
    image,
    weight,
    title,
    description,
  } = props

  const {
    updateSession,
  } = db

  const dispatch = useDispatch()

  const accountData = useSelector(state => state.accountState.account)

  const [expanded, setExpanded] = useState(false)

  const toggle = () => {
    setExpanded(!expanded)

    let flags = []
    if (deal.exclusive) {
      flags.push('exclusive')
    }
    if (deal.community) {
      flags.push('community')
    }

    dispatch(
      updateSession({
        selected_deal_tracking: {
          basicKeywords: deal.keywords && deal.keywords.length ? deal.keywords : [],
          brand: brandData.label,
          flags: flags.join(', '),
          label: deal.label,
          node: parseInt(deal.nid, 10),
          primaryRewardsCategory: deal.category,
          secondaryRewardsCategory: deal.secondaryCategory,
          redemptionType: redemptionType,
          title: deal.title,
          url: '',
        },
      }),
    )

    analytics.track('Offer Viewed', {
      basicKeywords: deal.keywords && deal.keywords.length ? deal.keywords : [],
      brand: brandData.label,
      flags: flags.join(', '),
      label: deal.label,
      node: parseInt(deal.nid, 10),
      primaryRewardsCategory: deal.category,
      secondaryRewardsCategory: deal.secondaryCategory,
      redemptionType: redemptionType,
      title: deal.title,
      url: '',
      context: {
        app: {
          name: 'MilVetID',
          namespace: 'components/Deals/DealsList/Deal',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
    })
  }

  const renderTerms = () => {
    if (terms) {
      return (
        <div className="terms">
          <h3>{staticData.term_and_conditions_label}</h3>
          {Parser(terms)}
        </div>
      )
    }
  }

  const renderButton = () => {
    // Check if there is a object with value from dealAccess, corresponding to the account role or
    // if this is vr_active_duty member, which grands access to every vr_member deal also or
    // if this is va_active_duty member, which grands access to every va_member deal also
    if (
      dealAccess.includes(accountData.roles[0]) ||
      (
        accountData.roles[0] === 'vr_active_duty' &&
        (
          dealAccess.includes('vr_member') ||
          dealAccess.includes('vr_family')
        )
      ) ||
      (
        accountData.roles[0] === 'va_active_duty' &&
        (
          dealAccess.includes('va_member') ||
          dealAccess.includes('va_family')
        )
      )
    ) {
      return (
        <div className="deal-action">
          <button className="btn btn-small" data-id={dataid} onClick={handleClick}>
            {staticData.button}
          </button>
        </div>
      )
    }
    else if (
      // If it is active duty deal, and the customer is not active duty role.
      (
        dealAccess.includes('va_active_duty') ||
        dealAccess.includes('vr_active_duty')
      ) &&
      (
        accountData.roles[0] !== 'va_active_duty' &&
        accountData.roles[0] !== 'vr_active_duty'
      ) &&
      !(
        dealAccess.includes('vr_family') ||
        dealAccess.includes('vr_member')
      )
    ) {
      return (
        <div className="upgrade-text active-duty">
          <p>
            {staticData.active_only_deal}
          </p>
        </div>
      )
    }
    else if (
      // If it is not family member deal, and the customer is family member.
      !(
        dealAccess.includes('va_family') ||
        dealAccess.includes('vr_family')
      ) &&
      (
        accountData.roles[0] === 'va_family' ||
        accountData.roles[0] === 'vr_family'
      )
    ) {
      return (
        <div className="upgrade-text active-duty">
          <p>
            {staticData.veterans_and_active_only_deal}
          </p>
        </div>
      )
    }
    else if (
      // If it is VA only deal.
      (
        dealAccess.includes('va_family') &&
        !dealAccess.includes('vr_family')
      ) ||
      (
        dealAccess.includes('va_member') &&
        !dealAccess.includes('vr_member')
      )
    ) {
      return (
        <div className="upgrade-text active-duty">
          <p>
            {staticData.va_only_deal}
          </p>
        </div>
      )
    }
    else if (
      accountData.roles &&
      (
        accountData.roles.includes('vr_household') ||
        accountData.roles.includes('va_household')
      )
    ) {
      return (
        <div className="upgrade-text active-duty">
          <p>
            {staticData.va_only_deal}
          </p>
        </div>
      )
    }
    else {
      return (
        <div className="upgrade-text">
          <p>
            {staticData.upgrade_description}
          </p>
          <button className="btn btn-small" data-id={dataid} onClick={handleClickUpdate}>
            {staticData.button_upgrade}
          </button>
        </div>
      )
    }
  }

  return (
    <div className="deal" style={{order: weight}}>
      <div className="discount" onClick={toggle}>
        <div className="image">
          <img src={image} alt={title}/>
        </div>
        <div className="description">
          {title}
        </div>
        {deal?.exclusive && <div className={'discount-type'}>
          {staticData?.deal_exclusive_label}
        </div>}
        <div className="discount-logo-wrapper">
            <span className="discount-logo">
              <img src={brandData.logo} alt={brandData.label}/>
            </span>
        </div>
      </div>
      <div className={expanded ? 'deal-description expanded' : 'deal-description'}>
        <h3>{staticData.details_of_the_deal_label}</h3>
        {Parser(description)}
        {renderTerms()}
        {renderButton()}
      </div>
      <div className={expanded ? 'toggle-icon close' : 'toggle-icon'} onClick={toggle}>
      </div>
    </div>
  )
}

export default Deal