const INITIAL_STATE = {
  status: 'loading',
  data: {},
}

const applySetData = (state, action) => ({
  status: 'loaded',
  data: action.payload,
})

function brandReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_BRAND_INFO' : {
      if (action.payload) {
        return applySetData(state, action)
      }
      else {
        return state
      }
    }
    default :
      return state
  }
}

export default brandReducer
