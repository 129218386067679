import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  useHistory,
} from 'react-router-dom'
import {db, analytics} from '../../actions'

import Preloader from '../Preloader'
import DealsList from './DealsList'
import * as routes from '../../constants/routes'
import {isFullyVerified} from "../../actions/identification";

const Deals = (props) => {
  const {
    staticData,
    hideDeals,
    brandId,
    exclusiveDealsOnly,
    communityDealsOnly,
    brandData,
    dealTypes,
    providerToken
  } = props

  const {
    updateSession,
    fetchDealsInfo
  } = db

  const history = useHistory()
  const dispatch = useDispatch()

  const authUser = useSelector(state => state.sessionState.authUser)
  const accountData = useSelector(state => state.accountState.account)
  const accountDataStatus = useSelector(state => state.accountState.status)
  const sessionDB = useSelector(state => state.sessionDBState)
  const dealsStatus = useSelector(state => state.dealsState.status)

  const [redirected, setRedirected] = useState(false)

  useEffect(() => {
    if ((!authUser || props.signOutUser) && !redirected) {
      history.push(routes.SIGN)
    }
  }, [authUser, history, props.signOutUser])

  useEffect(() => {
    if (authUser) {
      if (hideDeals) {
        dispatch(
          updateSession({
            selected_deal_id: 'Skipped deal selection',
          }, sessionDB.id),
        )

        history.push(routes.STATIC_SEND_PARTNER_DATA)
      }

      analytics.page('Deals', {uid: authUser.uid})
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (accountDataStatus !== 'loading' && accountData) {
      if (isFullyVerified(accountData)) {
        // Fetch again the data with the updates info.
        dispatch(
          fetchDealsInfo(brandId, exclusiveDealsOnly, communityDealsOnly),
        )
      }
      else {
        history.push(routes.SIGN)
      }
    }

    // eslint-disable-next-line
  }, [accountDataStatus])

  const title = staticData.title.replace('@brand', brandData.label)

  if (accountDataStatus === 'loading') {
    return (
      <Preloader title="Loading account data. Please wait."/>
    )
  }
  else {
    return (
      <div className="page-deals-marketplace">
        <h1>{title}</h1>
        {dealsStatus === 'loading' &&
        <Preloader title="Loading deals information. Please wait."/>}
        {dealsStatus === 'loaded' &&
        <DealsList
          staticData={staticData}
          providerToken={providerToken}
          dealTypes={dealTypes}
          brandData={brandData}
          setRedirected={setRedirected}
        />}
      </div>
    )
  }
}

export default Deals
