import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './components/App'
import ErrorBoundary from './components/ErrorBoundary'
import store from './store'
import { unregister } from './registerServiceWorker'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from "@sentry/tracing";
const pkg = require('../package.json');

// Prepare Sentry init object.
let sentryInit = {
  dsn: process.env.REACT_APP_SENTRYKEY,
  environment: process.env.REACT_APP_ENV,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ? parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE) : 0.2
};

// Prepare Sentry additional variables.
if (process.env.REACT_APP_GITHUB_SHA) {
  const env = process.env.REACT_APP_ENV || 'local';
  // Set App release.
  let APP_RELEASE = `${env}-${process.env.REACT_APP_GITHUB_SHA}`;
  // Get prod tag name.
  if (process.env.REACT_APP_ENV === 'prod') {
    APP_RELEASE = process.env.REACT_APP_GITHUB_REF;
  }
  sentryInit.release = `${pkg.name}-${APP_RELEASE}`;
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log(sentryInit);
  }
}

// Init Sentry with no available build variables.
Sentry.init(sentryInit)

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
)
unregister()
