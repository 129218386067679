import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import {analytics, db, helpers} from '../../actions'
import * as routes from '../../constants/routes'
import {firebase} from '../../firebase'

import Preloader from '../Preloader'
import Button from '../Common/Button'
import Sign from '../Sign'
import SendToPartnerConsent from '../StaticPages/SendToPartnerConsent'
import ContactUs from '../StaticPages/ContactUs'
import Deals from '../Deals'
import Header from "../Common/Header";

const App = (props) => {
  const {
    updateSession,
    fetchBrandInfo,
    fetchStaticTexts,
    fetchConfig,
    setErrorPage,
    clearErrorPage,
    fetchAccountInfo,
    onSetAuthUser,
    createSessionId,
  } = db

  const dispatch = useDispatch()

  const [parentProps, setParentProps] = useState(false)

  const [zoidComponents, setZoidComponents] = useState(null)

  const [userSuspended, setUserSuspended] = useState(false)
  const [errorInitiate, setErrorInitiate] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [initSegment, setInitSegment] = useState(false)
  const [identifyExecuted, setIdentifyExecuted] = useState(false)

  const authUser = useSelector(state => state.sessionState.authUser)
  const accountDataStatus = useSelector(state => state.accountState.status)
  const accountData = useSelector(state => state.accountState.account)
  const staticData = useSelector(state => state.textsState.data)
  const staticDataStatus = useSelector(state => state.textsState.status)
  const sessionDB = useSelector(state => state.sessionDBState.data)
  const sessionDBId = useSelector(state => state.sessionDBState.id)
  const brandData = useSelector(state => state.brandState.data)
  const brandDataStatus = useSelector(state => state.brandState.status)
  const errorPageData = useSelector(state => state.errorState.active)
  const configData = useSelector(state => state.configState.config)
  const maintenancePageData = useSelector(state => state.errorState.maintenance)
  let brandDataItems

  const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1
  const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge
  const textIE = {
    'title': 'Your browser is not supported.',
    'description': 'Please, use Microsoft Edge, Chrome, Safari or Firefox for better Web experience.',
    'chat_link': 'https://www.veteransadvantage.com/about/contact',
    'chat_link_label': 'Chat with our VIP Support Team',
    'call_link': 'tel://+18668382774',
    'call_link_label': 'Call now: 1-866-VET-ASSIST',
  }

  /**
   * @see CX-1681 fixing Fetch API error on page relaod or redirect.
   */
  useEffect(() => {
    const unloadCallback = () => {firebase.firebase.app().delete()}
    window.addEventListener("beforeunload", unloadCallback)
    return async () => {
      window.removeEventListener("beforeunload", unloadCallback)
    }
  }, [])

  // Execute lightweight identify call on initial app load.
  useEffect(() => {
    if (!identifyExecuted && accountData?.memberId) {
      Sentry.setUser({id: accountData?.memberId})
      analytics.identify()
      setIdentifyExecuted(true)
    }
  }, [accountData, identifyExecuted])

  // Inject UserLeap surveys widget.
  useEffect(() => {
    if (apiLoaded && identifyExecuted && accountData) {
      const script = document.createElement("script")
      script.id = "user-leap-js"
      script.innerHTML = `
        (function(l,e,a,p) {
          if (window.UserLeap) return;
          window.UserLeap = function(){U._queue.push(arguments)}
          var U = window.UserLeap;U.appId = a;U._queue = [];
          a=l.createElement('script');
          a.id='user-leap-script';a.async=1;a.src=e+'?id='+U.appId;
          p=l.getElementsByTagName('script')[0];
          var s = document.getElementById('user-leap-script');
          !s && (p.parentNode.insertBefore(a, p));
        })(document, 'https://cdn.userleap.com/shim.js', '${'prod' === process.env.REACT_APP_ENV ? 'JpHTsIiXbx' : 'eri-YOvzg'}');
        UserLeap('setUserId', '${accountData.memberId}');
        UserLeap('setEmail', '${accountData.email}');`
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [accountData, identifyExecuted, apiLoaded])

  // Inject Osano script.
  useEffect(() => {
      const exists = document.getElementById('osano-script');
      if (!exists) {
        const script = document.createElement("script")
        script.id = "osano-script"
        script.src = "https://cmp.osano.com/16CVviSfKyjoO1UJH/678a0818-0589-4c84-8f03-38d55fd4f6e4/osano.js"
        const scriptNodes = document.head.getElementsByTagName('script')
        const scriptNode = scriptNodes.length ? scriptNodes[0] : null
        if (scriptNode) {
          scriptNode.parentNode.insertBefore(script, scriptNode)
        }
        else {
          document.head.insertBefore(script, null)
        }
        return () => {
          document.head.removeChild(script)
        }
      }
  }, [])

  // Check when window.xprops applied and set parentProps to true.
  useEffect(() => {

    let checkInterval = setInterval(() => {
      if (window.xprops) {
        setParentProps(true)
      }
    }, 600);

    return () => {
      clearTimeout(checkInterval)
    }
    // eslint-disable-next-line
  }, [])

  // On parentProps change.
  // Initiate and load libraries.
  useEffect(() => {
    if (!parentProps) {
      loadZoidScripts()
    }
    else if (!zoidComponents) {
      let updates = {}
      // Set default values if they are missing.
      if (typeof (window.xprops.publicComputer) === 'undefined') updates.publicComputer = false
      if (typeof (window.xprops.exclusiveDealsOnly) === 'undefined') updates.exclusiveDealsOnly = false
      if (typeof (window.xprops.communityDealsOnly) === 'undefined') updates.communityDealsOnly = false
      if (typeof (window.xprops.hideDeals) === 'undefined') updates.hideDeals = false
      if (typeof (window.xprops.dealTypes) === 'undefined') updates.dealTypes = ['Online Redemption']
      // Override options so the partner setup can't change them.
      updates.militaryQuestionsVerification = true
      updates.additionalExternalVerification = false
      updates.idVerification = false

      setZoidComponents({
        ...window.xprops,
        ...updates,
      })
    }
  }, [parentProps, zoidComponents])

  useEffect(() => {
    if (
      !initSegment &&
      typeof (window.analytics) !== 'undefined'
      && typeof (window.analytics.load) !== 'undefined'
    ) {
      let segmentKey = process.env.REACT_APP_SEGMENTKEY

      analytics.load(segmentKey)
      setInitSegment(true)
    }

    // eslint-disable-next-line
  }, [window.analytics])

  useEffect(() => {
    // State the version of app.
    if (process.env.REACT_APP_ENV === 'dev') {
      console.log(process.env.REACT_APP_VERSION)
    }

    // Get url arguments and if there is sessionId already present set it or create new.
// Process required query params.
    let urlQuery = {}
    const urlQueryParams = new URLSearchParams(window.location.search)
    for (const [key, value] of urlQueryParams.entries()) {
      urlQuery[key] = value
    }

    if (typeof (urlQuery.sessionId) !== 'undefined') {
      dispatch(
        createSessionId(urlQuery.sessionId),
      )
    }
    else {
      dispatch(
        updateSession({newSession: true}),
      )
      dispatch(
        createSessionId(),
      )
    }

    // Save query in session.
    dispatch(
      updateSession({urlQuery: urlQuery}),
    )

    // Get all the static texts.
    dispatch(
      fetchStaticTexts(),
    )

    // Get the specific settings of the app.
    dispatch(
      fetchConfig(),
    )

    firebase.authRef.onAuthStateChanged(authUser => {
      if (authUser) {
        dispatch(
          onSetAuthUser(authUser),
        )
        dispatch(
          fetchAccountInfo(authUser.uid),
        )
      }
      else {
        dispatch(
          onSetAuthUser(null),
        )
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (apiLoaded && zoidComponents) {
      // Check if there is a valid callback for the app.
      if (typeof (zoidComponents.dataPartner) === 'undefined') {
        console.log('==============================================')
        console.log('MilVetID Setup Error:')
        console.log('==============================================')
        console.log('No Callback Function or callback url found.')
        console.log('You need to set up "verifyCallback" or "dataPartner"')
        console.log('==============================================')
        dispatch(
          setErrorPage('There were problem setting up the app. Please, contact us for more information.'),
        )
      }

      // Get the brand info provided by the partner.
      if (typeof (zoidComponents.providerToken) !== 'undefined') {
        dispatch(
          fetchBrandInfo(zoidComponents.providerToken),
        )
      }
      else if (window.xprops) {
        console.log('==============================================')
        console.log('MilVetID Setup Error:')
        console.log('==============================================')
        console.log('No provider token found.')
        console.log('==============================================')
        dispatch(
          setErrorPage('There were problem setting up the app. Please, contact us for more information.'),
        )
      }
    }
    // eslint-disable-next-line
  }, [apiLoaded, zoidComponents])

  useEffect(() => {
    if (authUser &&
      accountData &&
      typeof (accountData.userStatus) !== 'undefined') {
      if (accountData.userStatus === 'suspended') {
        setUserSuspended(true)
      }
      else {
        setUserSuspended(false)
      }
    }
  }, [authUser, accountData])

  useEffect(() => {
    if (
      configData &&
      typeof (configData.maintenance) !== 'undefined' &&
      typeof (configData.maintenance.status) !== 'undefined'
    ) {
      checkMaintenance()
    }
    // eslint-disable-next-line
  }, [configData])

  useEffect(() => {
    if (authUser) {
      // Fetch account infromation.
      if (accountDataStatus !== 'loaded') {
        dispatch(
          fetchAccountInfo(authUser.uid),
        )
      }
    }
    // eslint-disable-next-line
  }, [authUser, accountDataStatus])

  useEffect(() => {
    if (
      !apiLoaded ||
      !zoidComponents ||
      staticDataStatus === 'loading' ||
      (
        authUser &&
        accountDataStatus === 'loading'
      ) ||
      sessionDBId === '' ||
      (
        (parentProps) &&
        (brandDataStatus === 'loading')
      )
    ) {
      setLoading(true)
    }
    else {
      setLoading(false)
    }
  }, [authUser, zoidComponents, apiLoaded, staticDataStatus, sessionDBId, parentProps, brandDataStatus, accountDataStatus])

  const loadZoidScripts = () => {
    let functionPath = process.env.REACT_APP_FUNCTION_URL

    // If we have providerToken, fetch brand info.
    helpers.loadScript('/loader/dynamic.js', 'access_key')
      .then(() => {
        setApiLoaded(true)
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('load-zoid-script', error)
        setErrorInitiate('App error')
      })

  }

  const checkMaintenance = () => {
    if (configData.maintenance.status) {
      dispatch(
        setErrorPage(configData.maintenance.title ? configData.maintenance.title : 'Maintenance', false, false, configData.maintenance.description ? configData.maintenance.description : 'We are currently performing scheduled maintenance operations. Functionality is currently limited. Thank you for your patience and please check back soon.', false, true),
      )
    }
    else if (
      !configData.maintenance.status
    ) {
      dispatch(
        clearErrorPage(true),
      )
    }
  }

  const openHelp = () =>
    dispatch(
      setErrorPage('Need Help?', false, true, 'Contact our VIP Member Support Team.'),
    )

  const setSignOut = () => {
    dispatch(
      createSessionId(),
    )
    dispatch(
      updateSession({userSignOut: true}),
    )
  }

  if (isIE) {
    return (
      <div className="app">
        <ContactUs
          staticData={textIE}
        />
      </div>
    )
  }
  else if (errorInitiate && staticDataStatus !== 'loading') {
    return (
      // If there is an error, load the error page.
      <main className="app">
        <ContactUs
          staticData={staticData.find(x => x.id === errorInitiate).data()}
        />
      </main>
    )
  }
  else if (loading) {
    return (
      // If the info is not loaded, show preloader.
      <Preloader title="Loading app. Please wait."/>
    )
  }
  else if (userSuspended) {
    return (
      // If there is an error, load the error page.
      <main className="app">
        <ContactUs
          staticData={staticData.find(x => x.id === 'Suspended Account').data()}
        />
      </main>
    )
  }
  else {
    // Set all the routes after the info is loaded.
    // Note: we are passing just the info that is needed, and filter the static data manually.
    return (
      <Router>
        <Header brandData={brandData.data()}/>
        <main className="app">
          {(errorPageData ||
              maintenancePageData) &&
            <ContactUs
              staticData={staticData.find(x => x.id === 'Contact Us').data()}
              signOutUser={!!sessionDB.userSignOut}
            />
          }
          <Route exact path={routes.SIGN} render={(props) => <Sign
            staticData={staticData.find(x => x.id === 'Sign Up').data()}
            providerToken={zoidComponents.providerToken}
            signOutUser={!!sessionDB.userSignOut}
            publicComputer={zoidComponents.publicComputer}
            milvetIdScriptVersion={sessionDB.urlQuery ? sessionDB.urlQuery.milvetIdScriptVersion : null}
          />}/>
          <Route exact path={routes.MDM_DEALS} render={(props) => <Deals
            signOutUser={!!sessionDB.userSignOut}
            staticData={staticData.find(x => x.id === 'Deal Marketplace').data()}
            brandData={brandData.data()}
            brandId={brandData.id}
            hideDeals={zoidComponents.hideDeals}
            providerName={zoidComponents.providerName}
            providerToken={zoidComponents.providerToken}
            dealTypes={zoidComponents.dealTypes}
            idVerification={zoidComponents.idVerification}
            militaryQuestionsVerification={zoidComponents.militaryQuestionsVerification}
            exclusiveDealsOnly={zoidComponents.exclusiveDealsOnly}
            communityDealsOnly={zoidComponents.communityDealsOnly}
          />}/>
          <Route exact path={routes.STATIC_SEND_PARTNER_DATA} render={(props) => <SendToPartnerConsent
            signOutUser={!!sessionDB.userSignOut}
            brandData={brandData.data()}
            brandId={brandData.id}
            staticData={staticData.find(x => x.id === 'Send To Partner Consent').data()}
            dataPartner={zoidComponents.dataPartner}
            hideDeals={zoidComponents.hideDeals}
            providerName={zoidComponents.providerName}
          />}/>
          <Route exact path={routes.CONTACT_US} render={(props) => <ContactUs
            signOutUser={!!sessionDB.userSignOut}
            staticData={staticData.find(x => x.id === 'Contact Us').data()}
            deniedVerificationCallback={typeof (zoidComponents.deniedVerificationCallback) !== 'undefined' ? zoidComponents.deniedVerificationCallback : false}
          />}/>
          {authUser &&
            <Button
              className={'btn-signout'}
              onClick={() => setSignOut()}
              text={'Sign Out'}
            />
          }
          <Button
            className={'btn-help-request'}
            onClick={() => openHelp()}
            text={'Need Help?'}
          />
        </main>
      </Router>)
  }
}

export default App
