import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {db, analytics, helpers} from '../../../actions'
import * as Sentry from '@sentry/browser'

const ContactUs = (props) => {
  const {
    staticData,
    deniedVerificationCallback,
  } = props

  const {
    clearErrorPage,
  } = db

  const dispatch = useDispatch()

  const authUser = useSelector(state => state.sessionState.authUser)
  const errorPageDataTitle = useSelector(state => state.errorState.title)
  const errorPageDataDesc = useSelector(state => state.errorState.desc)
  const errorPageDataBtn = useSelector(state => state.errorState.btn)
  const errorPageDataBack = useSelector(state => state.errorState.back)
  const errorPageDataState = useSelector(state => state.errorState.active)
  const errorPageDataExternalBtn = useSelector(state => state.errorState.externalBtn)
  const sessionDB = useSelector(state => state.sessionDBState)
  const chatConfigData = useSelector(state => state.configState.chat)


  const [status, setStatus] = useState('start')
  const [closeWidgetButton, setCloseWidgetButton] = useState(false)
  const [initiateSign, setInitiateSign] = useState(false)


  useEffect(() => {
    document.body.classList.add('page-lightest-grey')

    if (authUser) {
      analytics.page('Contact Us', {
        title: errorPageDataState ? errorPageDataTitle : staticData.title,
        uid: authUser.uid,
      })
    }
    else {
      analytics.page('Contact Us', {
        title: errorPageDataState ? errorPageDataTitle : staticData.title,
      })
    }

    if (
      authUser &&
      !errorPageDataState &&
      deniedVerificationCallback
    ) {
      deniedVerificationCallback(sessionDB.id)

      setCloseWidgetButton(true)
    }

    return () => document.body.classList.remove('page-lightest-grey')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (chatConfigData) {
      do_load()
    }
  }, [chatConfigData])

  useEffect(() => {
    const kustomerInit = () => {
      Kustomer.start({
        brandId: process.env.REACT_APP_KUSTOMER_API_BRAND,
      }, function () {
        setStatus('done')
      })
    }

    if (status === 'done') {
      setTimeout(() => openChat(), 4000)
      window.removeEventListener('kustomerLoaded', kustomerInit)
    }

    window.addEventListener('kustomerLoaded', kustomerInit)
    return () => window.removeEventListener('kustomerLoaded', kustomerInit)
    // eslint-disable-next-line
  }, [status])

  useEffect(() => {
    if (status === 'done' && authUser && !initiateSign && chatConfigData.config.disabled === 1) {
      setInitiateSign(true)

      helpers.getJWTKustomerToken()
        .then(token => {
          window.Kustomer.login({
            jwtToken: token.data,
          }, (loginCallbackResponse, error) => {
            if (error) {
              Sentry.captureException(error)
              console.error(error)
            }
          })
        })
        .catch(error => {
          Sentry.captureException(error)
          console.error(error)
        })
    }
    // eslint-disable-next-line
  }, [status, authUser])

  const new_script = (src, id, dataKustomerApiKey) => {
    return new Promise(function (resolve, reject) {
      let script = document.createElement('script')
      script.src = src
      script.id = id
      if (dataKustomerApiKey) {
        script.setAttribute('data-kustomer-api-key', dataKustomerApiKey)
      }
      script.addEventListener('load', function () {
        resolve()
      })
      script.addEventListener('error', function (e) {
        reject(e)
      })
      document.body.appendChild(script)
    })
  }

  const do_load = () => {
    setStatus('loading')

    if (chatConfigData.config.disabled === 1) {
      let kustomer_script = new_script('https://cdn.kustomerapp.com/chat-web/widget.js', 'kustomer-snippet', process.env.REACT_APP_KUSTOMER_API_KEY)

      kustomer_script
        .catch(error => {
          Sentry.captureException(error)
          console.error(error)
          setStatus('error')
        })
    }
    else {
      let zendesk_script = new_script('https://static.zdassets.com/ekr/snippet.js?key=' + process.env.REACT_APP_ZENDESK_CHAT_KEY, 'ze-snippet')

      zendesk_script.then(() => {
        var counter = 0

        var refreshIntervalId = setInterval(
          () => {
            if (typeof (window.$zopim) !== 'undefined' && typeof (window.$zopim.livechat) !== 'undefined') {
              setStatus('done')

              clearInterval(refreshIntervalId)
            }
            // If the script have not loaded for 5 sec, then stop trying.
            else if (counter >= 10) {
              setStatus('undefined')

              clearInterval(refreshIntervalId)
            }
          }
          , 500)
      }).catch(error => {
        Sentry.captureException(error)
        console.error(error)
        setStatus('error')
      })
    }
  }

  const openChat = () => {
    if (status === 'done') {

      if (chatConfigData.config.disabled === 1) {
        Kustomer.open()
      }
      else {
        void (window.$zopim.livechat.window.show())

        if (authUser) {
          if (authUser.displayName) {
            void (window.$zopim.livechat.setName(authUser.displayName))
          }

          if (authUser.email) {
            void (window.$zopim.livechat.setEmail(authUser.email))
          }

          if (authUser.phoneNumber) {
            void (window.$zopim.livechat.setPhone(authUser.phoneNumber))
          }
        }
      }
    }
  }

  const closeWidget = () => {
    window.xprops.close()
  }

  const resetApp = () => {
    dispatch(
      clearErrorPage(),
    )
  }

  return (
    <div className='page-contact-us'>
      {errorPageDataState &&
      errorPageDataBack &&
      <button className="btn-back" onClick={() => resetApp()}/>
      }
      <h1>
        {errorPageDataState ? errorPageDataTitle : staticData.title}
      </h1>
      {!errorPageDataState &&
      <p>{staticData.description}</p>
      }
      {errorPageDataDesc !== '' &&
      <p>{errorPageDataDesc}</p>
      }
      {status === 'done' &&
      <button onClick={(event) => openChat(event)} className="btn-link no-hover chat-link support-link" target="_blank">
        {staticData.chat_link_label}
      </button>
      }
      <a href={staticData.call_link} className="call-link support-link" target="_blank" rel="noopener noreferrer">
        {staticData.call_link_label}
      </a>
      {errorPageDataState &&
      errorPageDataExternalBtn &&
      <div className="retry">
        <br/>
        <br/>
        <a className="btn btn-red btn-medium" href={errorPageDataExternalBtn.link} target="_blank"
           rel="noopener noreferrer">
          {errorPageDataExternalBtn.title}
        </a>
      </div>
      }
      {errorPageDataState &&
      errorPageDataBtn &&
      <div className="retry">
        <br/>
        <br/>
        <button className="btn-red btn-medium" onClick={() => resetApp()}>
          {staticData.retry_link_title}
        </button>
      </div>
      }

      {closeWidgetButton &&
      <div className="retry">
        <br/>
        <br/>
        <button className="btn-red btn-medium" onClick={() => closeWidget()}>
          {staticData.close_verification_btn}
        </button>
      </div>
      }
    </div>
  )
}


export default ContactUs
